import { createMuiTheme } from "@material-ui/core/styles";

const appBackground = "#f4f4f4";

export default createMuiTheme({
  palette: {
    background: {
      default: appBackground,
    },
  },
});
