import React from "react";
import { Grid, Typography, Link, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import gwdLabIcon from "./assets/gwdLabIcon.png";

const useStyles = makeStyles((theme) => ({
  gwdLabIcon: {
    height: "10em",
    // [theme.breakpoints.down("sm")]: { height: "9em" },
    // [theme.breakpoints.down("xs")]: { height: "7.5em" },
  },
}));

const Copyright = () => {
  const classes = useStyles();

  return (
    <>
      <Divider variant='inset' />
      <Grid
        style={{ marginTop: "5em" }}
        item
        container
        direction='column'
        alignItems='center'
        justify='center'
      >
        <Grid item>
          <img
            className={classes.gwdLabIcon}
            alt='GWD Lab Icon'
            src={gwdLabIcon}
          />
        </Grid>
        <Grid item>
          <Typography variant='body2' color='textSecondary' align='center'>
            {"Copyright © "}
            <Link color='inherit' href='https://www.aurora-schools.org/'>
              <strong>GreenWorks Design Lab</strong> at Aurora City Schools,
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default Copyright;
