import React from "react";
import { NavLink } from "react-router-dom";
import {
  Typography,
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Link,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Copyright from "./Copyright";
import asfLogo from "./assets/asfLogo.png";
import ascicLogo from "./assets/ascicLogo.png";
import homePic01 from "./assets/tempPic01.jpeg";
import homePic02 from "./assets/tempPic02.jpeg";
import homePic03 from "./assets/tempPic03.jpeg";

const useStyles = makeStyles((theme) => ({
  ascicLogo: {
    height: "30em",
    [theme.breakpoints.down("sm")]: { height: "22em" },
    [theme.breakpoints.down("xs")]: { height: "16em" },
  },
  asfLogo: {
    height: "25em",
    // [theme.breakpoints.down("sm")]: { height: "12em" },
    // [theme.breakpoints.down("xs")]: { height: "9.5em" },
  },
  heroText: {
    marginTop: "1em",
    fontSize: "3em",
    [theme.breakpoints.down("sm")]: { fontSize: "2.5em" },
    [theme.breakpoints.down("xs")]: { fontSize: "2.0em" },
    lineHeight: 1.1,
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    marginTop: "3em",
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.common.panelColor,
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  logoRow: {
    marginTop: "3em",
    marginBottom: "3em",
  },
  footer: {
    padding: theme.spacing(3),
  },
}));

const cards = [
  {
    key: 1,
    image: homePic01,
    heading: "No Experience Needed",
    content: (
      <Typography style={{ marginTop: "1em" }}>
        It doesn't matter if you have no experience with design, or with any of
        the tools in our lab. Everyone has to start somewhere, and our space can
        be anyone's starting place.
      </Typography>
    ),
  },
  {
    key: 2,
    image: homePic02,
    heading: "Collaboration",
    content: (
      <Typography style={{ marginTop: "1em" }}>
        This isn't a library. Don't get us wrong, you can come here just to
        learn and develop personal skills, but we recognize collaboration as the
        fuel for impactful innovation. Our space is built for it."
      </Typography>
    ),
  },
  {
    key: 3,
    image: homePic03,
    heading: "Our Facility",
    content: (
      <Typography style={{ marginTop: "1em" }}>
        It is our goal to make the equipment in our space as open and
        appraochable as possible. Please{" "}
        <NavLink to='/facility'>click here for all facility details</NavLink>{" "}
        including learning resources and scheduling.
      </Typography>
    ),
  },
];

const Home = () => {
  const classes = useStyles();

  return (
    <>
      <Container style={{ marginTop: "10em" }}>
        <Grid container direction='column' alignItems='center'>
          <Grid item>
            <img
              className={classes.ascicLogo}
              alt='ASCIC Logo'
              src={ascicLogo}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.heroText} align='center'>
              An inclusive and collaborative space for community members to
              design, collaborate, and create.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container className={classes.cardGrid} maxWidth='md'>
        <Grid container spacing={4}>
          {cards.map((card) => (
            <Grid item key={card.key} xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image={card.image}
                  title='Image title'
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant='h5' component='h2'>
                    {card.heading}
                  </Typography>
                  <Divider variant='middle' />
                  {card.content}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Grid item container direction='column'>
        <Grid item>
          <Typography variant='h5' align='center' color='textSecondary'>
            Our design facility is hosted out of our GreenWorks Design Lab, and
            has been proudly sponsored by the{" "}
            <Link
              href='https://auroraschoolsfoundation.org/'
              target='_blank'
              rel='noreferrer'
            >
              Aurora Schools Foundation
            </Link>
            .
          </Typography>
        </Grid>
        <Grid
          className={classes.logoRow}
          item
          container
          direction='column'
          justify='center'
          alignItems='center'
        >
          <Grid item>
            <img className={classes.asfLogo} alt='ASF Logo' src={asfLogo} />
          </Grid>
        </Grid>
      </Grid>
      {/* Footer */}
      <footer className={classes.footer}>
        <Copyright />
      </footer>
      {/* End footer */}
    </>
  );
};

export default Home;
