import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import FacilityIcon from "@material-ui/icons/Apartment";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontSize: "2em",
    textShadow: "2px 2px grey",
    [theme.breakpoints.down("sm")]: { fontSize: "1.5em" },
    [theme.breakpoints.down("xs")]: { fontSize: "1em", textShadow: "none" },
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
}));

const Header = () => {
  const classes = useStyles();
  const history = useHistory();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (isOpen) => {
    setDrawerOpen(isOpen);
  };

  const handleDrawerItemClick = (isOpen, route) => {
    history.push(`/${route}`);
    toggleDrawer(isOpen);
  };

  return (
    <div className={classes.root}>
      <AppBar position='fixed'>
        <Toolbar style={{ backgroundColor: "#236661" }}>
          <IconButton
            edge='start'
            className={classes.menuButton}
            color='inherit'
            aria-label='menu'
            onClick={() => toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor='left'
            open={drawerOpen}
            onClose={() => toggleDrawer(false)}
          >
            <List className={classes.list}>
              <ListSubheader component='div' id='nested-list-subheader'>
                Navigation Areas
              </ListSubheader>
              <ListItem button onClick={() => handleDrawerItemClick(false, "")}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary='Home' />
              </ListItem>
              <ListItem
                button
                onClick={() => handleDrawerItemClick(false, "facility")}
              >
                <ListItemIcon>
                  <FacilityIcon />
                </ListItemIcon>
                <ListItemText primary='Our Facility' />
              </ListItem>
            </List>
          </Drawer>
          <Typography variant='h6' className={classes.title}>
            Aurora Schools CIC
          </Typography>
          <Button color='inherit' variant='outlined'>
            Admin Portal
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
