import React from "react";
import {
  Container,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import UltimakerPrinter from "./assets/ultimakerPrinter.jpeg";
import LaserCutting from "./assets/laserCutting.jpeg";
import XCarve from "./assets/xcarve.png";
import Electronics from "./assets/electronicsLab.jpeg";
import Workstations from "./assets/macStations.jpeg";
import Woodworking from "./assets/woodworkingArea.jpeg";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  gridRoot: { flexGrow: 1 },
  media: {
    height: 350,
  },
  content: { height: 200 },
});

const components = [
  {
    image: UltimakerPrinter,
    title: "Ultimaker S5",
    heading: "3D Printers",
    description:
      "Additive technologies such as 3D printers allow for the creation of anything that can be modeled. Using special filaments and finely tuned components, our line of Ultimaker 3D printers cancreate anything a designer has modeled from their imagination.",
    forPublicUse: true,
  },
  {
    image: LaserCutting,
    title: "Laser Cutting",
    heading: "Laser Cutting and Engraving",
    description:
      "When fine, scaled, precision cutting through wood, acrylic, and thin metals is called for, our Epilog laser cutter is always up for the challenge.",
    forPublicUse: true,
  },
  {
    image: XCarve,
    title: "CNC Routing",
    heading: "CNC Routing",
    description:
      "Subtractive technologies can be applied to a wide variety of materials, allowing for the design of finely crafted solid objects with precision detail.",
    forPublicUse: true,
  },
  {
    image: Electronics,
    title: "Electronics Area",
    heading: "Electronics Area",
    description:
      "In modern times, design often involves working with digital components. Wires, PCBs, discrete components, and the solder that binds them together; we have the facility to support it all.",
    forPublicUse: true,
  },
  {
    image: Workstations,
    title: "Workstations",
    heading: "Workstations",
    description:
      "Design and research go hand-in-hand. Many will also find the need for computing power to generate models and fashion files that can be interpreted by our design equipment. We have the computers and software you'll need.",
    forPublicUse: true,
  },
  {
    image: Woodworking,
    title: "Woodworking Area",
    heading: "Woodworking Area",
    description:
      "Not yet open to the public, our design lab specilists are able to assist with any aspect of our woodworking area invovling all sorts of equipment able to impart life-ending injury.",
    forPublicUse: false,
  },
];

const Facility = () => {
  const classes = useStyles();

  return (
    <Container style={{ marginTop: "10em" }}>
      <Grid container className={classes.gridRoot} spacing={2}>
        <Grid item xs={12}>
          <Grid container justify='center' spacing={4}>
            {components.map((component) => (
              <Grid key={component.title} item>
                <Card className={classes.root}>
                  <CardActionArea>
                    <CardMedia
                      className={classes.media}
                      image={component.image}
                      title={component.title}
                    />
                    <CardContent className={classes.content}>
                      <Typography gutterBottom variant='h5' component='h2'>
                        {component.heading}
                      </Typography>
                      <Typography
                        variant='body2'
                        color='textSecondary'
                        component='p'
                      >
                        {component.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  {component.forPublicUse && (
                    <CardActions>
                      <Button size='small' color='primary'>
                        Learn How to Use This
                      </Button>
                      <Button size='small' color='primary'>
                        Schedule This
                      </Button>
                    </CardActions>
                  )}
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Facility;
